<!--
File: Report_7_RoadConditionData.vue
Description: produce report showin detailed condition data as per selected parameters.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-15">
          <RegionsDropdown class='region-select-dropdown' :label="$t('label.select_region')" v-model="selectedRegion"
            :initial_value="selectedRegion" @input='onRegionChange' />
        </div>
        <!--div class="md-layout-item md-small-size-100 md-size-20">
          <DepsDropdown class='region-select-dropdown' :label="$t('label.select_deu')" v-model="selectedDeu"
            :initial_value="selectedDeu" :region_id="selectedRegion" @input='onDeuChange' :disabled='!selectedRegion' />
        </div>-->
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RoadsDropdown :label="$t('label.select_road')" v-model="selectedRoad" :initial_value="selectedRoad"
            :region_id="selectedRegion" :disabled='!selectedRegion' @input='onRoadChange' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <SectionsDropdown class='region-select-dropdown' :label="$t('label.select_section')" v-model="selectedSection"
            :region_id="selectedRegion" :road_id="selectedRoad" :disabled='!selectedRegion' @input='onSectionChange' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <md-datepicker v-model="startDate" md-immediately :md-open-on-focus="false">
            <label>{{ $t('label.start_date') }}</label>
          </md-datepicker>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <md-datepicker v-model="endDate" md-immediately :md-open-on-focus="false">
            <label>{{ $t('label.end_date') }}</label>
          </md-datepicker>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="print" :disabled="btnDisabled"> {{ $t('buttons.print') }}
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" class="md-progress-spinner" :md-diameter="70"
        md-mode="indeterminate" />
      <md-table id="printMe" class="paginated-table table-striped table-hover" :value="report" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell v-if="!selectedRegion" :md-label="$t('road_network.region')">
            {{ item.region_description }}
          </md-table-cell>
          <md-table-cell v-if="!selectedDeu" :md-label="$t('road_network.dep')" md-sort-by="deu_description">
            {{ $t('road_network.dep') + '-' + item.deu_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.section_description')" md-sort-by="section_description">
            {{ item.section_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.start_km')" md-sort-by="start_distance_km" md-numeric>
            {{ item.survey_start_distance_km | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.end_km')" md-sort-by="end_distance_km" md-numeric>
            {{ item.survey_end_distance_km | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.cracking_m2')" md-sort-by="total_cracking_m2" md-numeric>
            {{ item.total_cracking_m2 | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.potholes_no')" md-sort-by="total_potholes_no" md-numeric>
            {{ item.total_potholes_no | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.rutting')" md-sort-by="average_rutting" md-numeric>
            {{ item.average_rutting | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.repairs_m2')" md-sort-by="total_repairs_m2" md-numeric>
            {{ item.total_repairs_m2 | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.edge_break_m2')" md-sort-by="total_edge_break_m2" md-numeric>
            {{ item.total_edge_break_m2 | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.iri')" md-sort-by="average_iri" md-numeric>
            {{ item.average_iri | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.survey_date')" md-sort-by="survey_date">
            {{ item.survey_date }}
          </md-table-cell>
        </md-table-row>
      </md-table>
      <!--<md-table id="printFooter">
        <md-table-row>
          <md-table-head colspan="6">{{ $t('road_network.total_length') }}</md-table-head>
          <md-table-head md-numeric>{{ total_length }}</md-table-head>
        </md-table-row>
      </md-table>-->
    </md-card-content>
  </md-card>
</template>
<script>
  import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
  //import DepsDropdown from '../Dropdowns/DepsDropdown.vue'
  import RoadsDropdown from '../Dropdowns/RoadsDropdown.vue'
  import SectionsDropdown from '../Dropdowns/SectionsDropdown.vue'
  import { numFormat } from "@/store/refdata/format_helpers"
  import { customSortMixin } from '@/customSortMixin'

  export default {
    mixins: [customSortMixin],
    name: 'report-road-condition-data',
    data() {
      return {
        selectedRegion: null,
        selectedDeu: null,
        selected_date: new Date(),
        startDate: null,           //1950,
        endDate: new Date(),       // 2024,
        selectedSection: null,
        selectedRoad: null,
        btnDisabled: true,

        showSpinner: false,
        regionDescr: null,
        roadDescr: null,
        sectionDescr: null,
        currentSort: 'region_description',
        currentSortOrder: 'asc'
      }
    },
    components: {
      RegionsDropdown,
      //DepsDropdown,
      RoadsDropdown,
      SectionsDropdown
    },

    created() {
      //this.chartsDataIsLoading = true
      const st = new Date()
      st.setFullYear(st.getFullYear() - 1)
      this.startDate = st
    },

    methods: {
      reloadData() {
        this.showSpinner = true
        this.btnDisabled = true
        const reportParams = {
          region_id: this.selectedRegion,
          deu_id: this.selectedDeu,
          road_id: this.selectedRoad,
          section_id: this.selectedSection,
          start_date: this.startDate.toISOString().substring(0, 10),
          end_date: this.endDate.toISOString().substring(0, 10)
        }
        this.$store.dispatch('REPORT_7_ROAD_CONDITION_DATA', reportParams).then(() => {
          this.btnDisabled = false
          this.showSpinner = false
        })
      },
      print() {
        this.showSpinner = true
        let prtHtml = "<h4 style='text-align:center'>" + this.$t('label.reports_title') + "</h4>"
        prtHtml += "<h4 style='text-align:center'>" + this.$t('label.report7_title')
        prtHtml += '&nbsp;' + this.startDate.toLocaleDateString() + ' - ' + this.endDate.toLocaleDateString() + '<br>'

        let titleFilters = ''
        let tableHeaders = '<tr>'
        if (this.selectedRegion) {
          titleFilters += this.$t('label.from_region', { name: this.regionDescr })
        } else {
          tableHeaders += `<th>${this.$t('road_network.region')}</th>`
        }
        if (this.selectedRoad) {
          titleFilters += ', &nbsp;' + this.$t('road_network.road') + ': ' + this.roadDescr
        }
        if (this.selectedSection) {
          titleFilters += ', &nbsp;' + this.$t('road_network.section') + ': ' + this.sectionDescr
        } else {
          tableHeaders += `<th>${this.$t('road_network.section')}</th>`
        }
        if (titleFilters !== '') prtHtml += '&nbsp;(' + titleFilters + ')'
        prtHtml += '</h4>'

        tableHeaders += `<th>${this.$t('road_network.dep')}</th>
          <th>${this.$t('road_network.start_km')}</th>
          <th>${this.$t('road_network.end_km')}</th>
          <th>${this.$t('condition.cracking_m2')}</th>
          <th>${this.$t('condition.potholes_no')}</th>
          <th>${this.$t('condition.rutting')}</th>
          <th>${this.$t('condition.repairs_m2')}</th>
          <th>${this.$t('condition.edge_break_m2')}</th>
          <th>${this.$t('condition.iri')}</th>
          <th>${this.$t('condition.survey_date')}</th>
        </tr>`

        let tableRows = ''
        this.report.forEach(item => {
          tableRows += '<tr>'
          if (!this.selectedRegion) tableRows += `<td>${item.region_description}</td>`
          if (!this.selectedSection) tableRows += `<td>${item.section_description}</td>`
          tableRows += `<td>${this.$t('road_network.dep') + '-' + item.deu_description}</td>
            <td class='numCell'>${numFormat(item.survey_start_distance_km, 3)}</td>
            <td class='numCell'>${numFormat(item.survey_end_distance_km, 3)}</td>
            <td class='numCell'>${numFormat(item.total_cracking_m2)}</td>
            <td class='numCell'>${numFormat(item.total_potholes_no)}</td>
            <td class='numCell'>${numFormat(item.average_rutting)}</td>
            <td class='numCell'>${numFormat(item.total_repairs_m2)}</td>
            <td class='numCell'>${numFormat(item.total_edge_break_m2)}</td>
            <td class='numCell'>${numFormat(item.average_iri)}</td>
            <td>${item.survey_date}</td>
          </tr>`
        })

        const PrintWin = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        PrintWin.document.write(`<!DOCTYPE html>
        <html>
          <head>
            <style>
              body { font-family: Arial, sans-serif; }
              table { width: 100%; border-collapse: collapse; margin: 20px 0; font-size: 11px; }
              th, td { border: 1px solid black; padding: 5px; overflow: visible; white-space: normal; word-wrap: break-word; }
              .numCell { text-align: right; padding-right: 5px; }
            </style>
          </head>
          <body>${prtHtml}
            <table>
              <thead>${tableHeaders}</thead>
              <tbody>${tableRows}</tbody>
            </table>
          </body>
        </html>`
        )

        PrintWin.document.close();
        this.showSpinner = false
        PrintWin.focus();
        PrintWin.print();
        PrintWin.close();
      },
      onRegionChange(region_id, regionDescr) {
        this.regionDescr = regionDescr
        this.reloadData()
      },
      onRoadChange(road_id, roadDescr) {
        this.roadDescr = roadDescr
        this.reloadData()
      },
      onSectionChange(section_id, sectionDescr) {
        this.sectionDescr = sectionDescr
        this.reloadData()
      },
    },

    computed: {
      report() {
        return this.customSort(this.$store.state.Reports.report_7_data, 'deu_description')
      },
      tableData() {
        let result = !this.selectedRegion ? this.report : this.report.filter((item) => {
          return item.region_id == this.selectedRegion
        })
        if (this.selectedDeu) {
          result = result.filter((item) => { return item.deu_id == this.selectedDeu })
        }
        if (this.selectedRoad) {
          result = result.filter((item) => { return item.road_id == this.selectedRoad })
        }
        if (this.selectedSection) {
          result = result.filter((item) => { return item.section_id == this.selectedSection })
        }
        return result
      },
      total_length() {
        return this.report.reduce((accu, currVal) => acc + currVal.length_km, 0,)
      },
    },

    watch: {
      startDate(newValue) {
        if (!newValue) this.startDate = new Date()
        else this.reloadData()
      },
      endDate(newValue) {
        if (!newValue) this.endDate = new Date()
        else this.reloadData()
      },
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.paginated-table table>tbody>tr>td {
  height: 36px;
  padding: 0px !important;
  padding-right: 24px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>